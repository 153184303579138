import * as CIOJs from "platform/analytics/CustomerIOJs.js";
import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";
import {TSMap} from "typescript-map";

interface CustomerIOUser {

    id: number;
    email: string;
}

enum CustomerIOAction {

    Tutorial = "tutorial",
    Deposit = "deposit",
}

class CustomerIOWeb {

    private static readonly _logger: Logger = Logger.Of("CustomerIO");
    private static _actions: TSMap<CustomerIOAction, (data: string[]) => void> = new TSMap<CustomerIOAction, (data: string[]) => void>();
    private static _queue: {event: string, data: any}[] = [];
    private static _user: CustomerIOUser;

    private constructor() {
    }

    public static setHandler = (action: CustomerIOAction, handler: (data: string[]) => void) => {
        this._actions.set(action, handler);
    }

    public static identify = (organizationId: string, siteId: string, user: CustomerIOUser) => {
        if (!this._user) {
            this._user = user;
            this._logger.debug("Identify");
            CIOJs.CustomerIOInject(organizationId, siteId, () => {
                const cio = (window as any)._cio;
                if (cio) {
                    cio.identify({
                        id: user.id,
                        email: user.email
                    });
                    cio.on("in-app:message-action", (event) => {
                        if (event?.detail) {
                            const cioAction: string = event.detail.name;
                            if (cioAction) {
                                const data: string[] = cioAction.split(":");
                                const handler: (data: string[]) => void = this._actions.get(data[0] as CustomerIOAction);
                                if (handler) {
                                    handler(data);
                                }
                            }
                        }
                    });
                }
                if (Utils.isArrayNotEmpty(this._queue)) {
                    this._queue.forEach(({event, data}) => {
                        this.track(event, data);
                    });
                }
                this._queue = [];
            });
        } else {
            this._user = user;
        }
    }

    public static page = () => {
        if (this._user) {
            const cio = (window as any)._cio;
            if (cio) {
                cio.page(window.location.href, {
                    IsMobile: WebUtil.isMobile()
                });
            }
        }
    }

    public static track = (event: string, data?: any) => {
        if (this._user) {
            const cio = (window as any)._cio;
            if (cio) {
                cio.track(event, data);
            }
        } else {
            this._queue.push({event, data});
        }
    }
}

export {
    CustomerIOUser,
    CustomerIOAction,
    CustomerIOWeb
};
